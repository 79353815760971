import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Products from '../../../components/Products';
import ContentImage from '../../../components/ContentImage';
import image_photo from '../../../images/products/grid-energy-storage-system/photo.jpeg';
import image_energystoragesystem from '../../../images/products/grid-energy-storage-system/儲能系統.png';
import image_3 from '../../../images/products/grid-energy-storage-system/網頁功能.jpg';
import image_4 from '../../../images/products/grid-energy-storage-system/系統功能.png';

const ProductsPage = () => {
  const { t } = useTranslation('products_grid-energy-storage-system');
  return (
    <Products pageTitle={t('title')} description={t('description')}>
      <ContentImage image={image_photo} title={t('t1.name')}>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t1.blurb')}
        </p>
      </ContentImage>
      <ContentImage
        reverse={true}
        image={image_energystoragesystem}
        title={t('t2.name')}
      >
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t2.blurb1')}
        </p>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t2.blurb2')}
        </p>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t2.blurb3')}
        </p>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t2.blurb4')}
        </p>
      </ContentImage>
      <ContentImage image={image_3} title={t('t3.name')}>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t3.blurb1')}
        </p>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t3.blurb2')}
        </p>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t3.blurb3')}
        </p>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t3.blurb4')}
        </p>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t3.blurb5')}
        </p>
      </ContentImage>
      <ContentImage reverse={true} image={image_4} title={t('t4.name')}>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t4.blurb1')}
        </p>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t4.blurb2')}
        </p>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t4.blurb3')}
        </p>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t4.blurb4')}
        </p>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t4.blurb5')}
        </p>
      </ContentImage>
    </Products>
  );
};

export default ProductsPage;
